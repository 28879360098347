// TEMPLATE

// XXL
@media only screen and (max-width: 1400px) {
}

// XL
@media only screen and (max-width: 1200px) {
}

// LG
@media only screen and (max-width: 992px) {
}

// MD
@media only screen and (max-width: 768px) {
}

// SM
@media only screen and (max-width: 576px) {
}

.fs-md-55 {
  font-size: 55px !important;
}

.fs-md-35 {
  font-size: 35px !important;
}

.fs-md-34 {
  font-size: 34px !important;
}

.fs-md-33 {
  font-size: 33px !important;
}

.fs-md-32 {
  font-size: 32px !important;
}

.fs-md-31 {
  font-size: 31px !important;
}

.fs-md-30 {
  font-size: 30px !important;
}

.fs-md-29 {
  font-size: 29px !important;
}

.fs-md-28 {
  font-size: 28px !important;
}

.fs-md-27 {
  font-size: 27px !important;
}

.fs-md-26 {
  font-size: 26px !important;
}

.fs-md-25 {
  font-size: 25px !important;
}

.fs-md-24 {
  font-size: 24px !important;
}

.fs-md-23 {
  font-size: 23px !important;
}

.fs-md-22 {
  font-size: 22px !important;
}

.fs-md-21 {
  font-size: 21px !important;
}

.fs-md-20 {
  font-size: 20px !important;
}

.fs-md-19 {
  font-size: 19px !important;
}

.fs-md-18 {
  font-size: 18px !important;
}

.fs-md-17 {
  font-size: 17px !important;
}

.fs-md-16 {
  font-size: 16px !important;
}

.fs-md-15 {
  font-size: 15px !important;
}

.fs-md-14 {
  font-size: 14px !important;
}

.fs-md-13 {
  font-size: 13px !important;
}

.fs-md-12 {
  font-size: 12px !important;
}

.fs-md-11 {
  font-size: 11px !important;
}

.fs-md-10 {
  font-size: 10px !important;
}

.fs-md-9 {
  font-size: 9px !important;
}

.fs-md-8 {
  font-size: 8px !important;
}

.fs-md-7 {
  font-size: 7px !important;
}

.fs-md-6 {
  font-size: 6px !important;
}

.fs-md-5 {
  font-size: 5px !important;
}

.fs-md-4 {
  font-size: 4px !important;
}

.fs-md-3 {
  font-size: 3px !important;
}

.fs-md-2 {
  font-size: 2px !important;
}

.fs-md-1 {
  font-size: 1px !important;
}

@media only screen and (max-width: 768px) {
  .fs-mb-35 {
    font-size: 35px !important;
  }

  .fs-mb-34 {
    font-size: 34px !important;
  }

  .fs-mb-33 {
    font-size: 33px !important;
  }

  .fs-mb-32 {
    font-size: 32px !important;
  }

  .fs-mb-31 {
    font-size: 31px !important;
  }

  .fs-mb-30 {
    font-size: 30px !important;
  }

  .fs-mb-29 {
    font-size: 29px !important;
  }

  .fs-mb-28 {
    font-size: 28px !important;
  }

  .fs-mb-27 {
    font-size: 27px !important;
  }

  .fs-mb-26 {
    font-size: 26px !important;
  }

  .fs-mb-25 {
    font-size: 25px !important;
  }

  .fs-mb-24 {
    font-size: 24px !important;
  }

  .fs-mb-23 {
    font-size: 23px !important;
  }

  .fs-mb-22 {
    font-size: 22px !important;
  }

  .fs-mb-21 {
    font-size: 21px !important;
  }

  .fs-mb-20 {
    font-size: 20px !important;
  }

  .fs-mb-19 {
    font-size: 19px !important;
  }

  .fs-mb-18 {
    font-size: 18px !important;
  }

  .fs-mb-17 {
    font-size: 17px !important;
  }

  .fs-mb-16 {
    font-size: 16px !important;
  }

  .fs-mb-15 {
    font-size: 15px !important;
  }

  .fs-mb-14 {
    font-size: 14px !important;
  }

  .fs-mb-13 {
    font-size: 13px !important;
  }

  .fs-mb-12 {
    font-size: 12px !important;
  }

  .fs-mb-11 {
    font-size: 11px !important;
  }

  .fs-mb-10 {
    font-size: 10px !important;
  }

  .fs-mb-9 {
    font-size: 9px !important;
  }

  .fs-mb-8 {
    font-size: 8px !important;
  }

  .fs-mb-7 {
    font-size: 7px !important;
  }

  .fs-mb-6 {
    font-size: 6px !important;
  }

  .fs-mb-5 {
    font-size: 5px !important;
  }

  .fs-mb-4 {
    font-size: 4px !important;
  }

  .fs-mb-3 {
    font-size: 3px !important;
  }

  .fs-mb-2 {
    font-size: 2px !important;
  }

  .fs-mb-1 {
    font-size: 1px !important;
  }
}
